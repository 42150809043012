import VueRouter from 'vue-router';

// const Portfolio = () => import('@/pages/Portfolio');
const Sky = () => import('@/pages/Sky');
const UnderConstruction = () => import('@/pages/UnderConstruction');
const Fishes = () => import('@/pages/Fishes');
const Valentine = () => import('@/pages/Valentine');
const Rachel = () => import('@/pages/Rachel');

const routes = [
  {
    path: '/',
    component: UnderConstruction
  },
  {
    path: '/sky',
    component: Sky
  },
  {
    path: '/piece',
    component: Fishes
  },
  {
    path: '/vday',
    component: Valentine
  },
  {
    path: '/valentines',
    component: Rachel
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
